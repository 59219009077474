<script setup>
import BaseSelectStockDestination from '../base/BaseSelectStockDestination.vue'
import BaseInputField from '../base/BaseInputField.vue'

import { ref, defineProps, computed, watch, reactive } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { getCurrentDay } from '@/utils/DateHelpers'

const props = defineProps(['stockItems'])
const batchStockForm = ref(null)

const store = useStore()
const stockStatusOptions = computed(() => store.getters.stockStatusOptions)
const loadingStock = computed(() => store.getters.loadingStock)

const dialog = ref(false)
const enableDocket = ref(false)
const isFormValid = ref(true)
const formData = reactive({
  status: '',
  docket: '',
  location: 0
})

const fields = ref([
  {
    key: 'status',
    label: 'Status',
    type: 'select',
    items: stockStatusOptions.value,
    variant: 'outlined',
    rules: [(v) => !!v || 'Status is required'],
    placeholder: 'Select stock status...',
    dense: true
  },
  {
    key: 'docket',
    label: 'Docket Number',
    type: 'text',
    variant: 'outlined',
    placeholder: 'Enter docket number...',
    dense: true
  }
])

const generatePayload = () => {
  const { status, docket, location } = formData
  const date = getCurrentDay()

  return props.stockItems.map(({ id }) => {
    const data = {
      id,
      date,
      status,
      tracklog: {}
    }
    if (status.toLowerCase() !== 'expired') {
      data.tracklog.destination_co_id = location
    }
    if (enableDocket.value) {
      data.tracklog.docket = docket
    }
    return data
  })
}

const submitForm = async () => {
  batchStockForm.value.validate()
  if (!isFormValid.value) return

  const payload = generatePayload()

  const res = await store.dispatch('bulkUpdateStockItem', payload)
  if (res !== 'error') {
    dialog.value = false
    store.dispatch('fetchStock')
  }
}

const resetForm = () => {
  initializeFormData()

  if (batchStockForm.value) {
    batchStockForm.value.reset()
  }
}

const initializeFormData = () => {
  formData.status = ''
  formData.docket = ''
  formData.location = 0
  enableDocket.value = false
}

watch(
  () => props.stockItems,
  () => {
    resetForm()
  },
  { deep: true }
)
</script>
<template>
  <v-dialog v-model="dialog" scrollable max-width="600px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        x-small
        color="info"
        :disabled="stockItems.length === 0"
        ><span class="d-flex align-center"
          ><font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Batch
          Edit</span
        ></v-btn
      >
    </template>
    <v-card>
      <v-card-text class="pt-4">
        <h6 class="text-button text-light-blue text-start">
          Batch Update Stock Items
        </h6>
        <v-form
          ref="batchStockForm"
          v-model="isFormValid"
          @submit.prevent="submitForm"
        >
          <BaseInputField
            :field="fields[0]"
            :value="formData[fields[0].key]"
            @input="
              (val) => {
                formData[fields[0].key] = val
              }
            "
          />
          <BaseSelectStockDestination
            v-if="formData[fields[0].key].toLowerCase() !== 'expired'"
            :destination="formData.location"
            :stock-status="formData[fields[0].key]"
            :required="true"
            label="Location"
            @update:destination="
              (val) => {
                formData.location = val
              }
            "
          />
          <v-checkbox
            v-model="enableDocket"
            label="Update Docket Number"
            x-small
            hide-details
            class="font-italic font-weight-medium mt-4 mb-2 pt-0"
          ></v-checkbox>
          <BaseInputField
            v-if="enableDocket"
            :field="fields[1]"
            :value="formData[fields[1].key]"
            @input="
              (val) => {
                formData[fields[1].key] = val
              }
            "
          />

          <v-row no-gutters class="justify-end">
            <v-btn color="error" text @click="resetForm" class="me-2"
              >Reset</v-btn
            >
            <v-btn
              color="primary"
              type="submit"
              :loading="loadingStock === 'bulk-update'"
              >Submit</v-btn
            >
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>
