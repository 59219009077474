<script setup>
import BaseDeleteConfirm from '../base/BaseDeleteConfirm.vue'
import BaseMenu from '../base/BaseMenu.vue'

import { ref, defineProps, getCurrentInstance, computed } from 'vue'
import { useStore } from '@/utils/VuexHelpers'

const props = defineProps(['pigItems'])
const deleteDialog = ref(null)

const vm = getCurrentInstance().proxy
const store = useStore()

const items = [
  {
    action: () => deletePigs(),
    icon: 'mdi-trash-can-outline',
    title: 'Delete Selected Pigs',
    key: 'delete'
  }
]

const loadingPigInfo = computed(() => store.getters.loadingPigInfo)
const pigNumberList = computed(() =>
  props.pigItems.map((pig) => pig.body_no).join(', ')
)
const isDeleteDisabled = computed(() => props.pigItems.length === 0)

const deletePigs = async () => {
  const res = await store.dispatch('bulkDeletePigInfo', {
    payload: props.pigItems,
    vm
  })
  if (res === 'success') {
    store.dispatch('fetchPigInfo', { vm })
    deleteDialog.value.closeDialog()
  }
}
</script>

<template>
  <BaseMenu :items="items">
    <template #item-delete="{ item }">
      <BaseDeleteConfirm
        ref="deleteDialog"
        activator-type="list-item"
        activator-class="bsp-list-item"
        :buttonText="item.title"
        :size="'small'"
        @deleteConfirm="() => item.action()"
        :disabled="isDeleteDisabled"
        title="Are you sure you want to delete these pigs?"
        :loading="loadingPigInfo === 'bulk-delete'"
      >
        <template v-slot:content>
          <div
            class="red--text text-overline font-weight-bold my-4 text-center"
          >
            This Action is irreversible!
          </div>
          <div class="text-subtitle-2">
            Selected Pig{{ pigItems.length > 1 ? 's' : '' }} ({{
              pigItems.length
            }}):
          </div>
          <div class="text-overline font-weight-bold">
            {{ pigNumberList }}
          </div>
        </template>
      </BaseDeleteConfirm>
    </template>
  </BaseMenu>
</template>
