import axios from 'axios'

import { PER_PAGE_OPTIONS } from '@/constants/pagination'
import { getISOWeekNumber, getWeekBounds, getYear } from '@/utils/DateHelpers'
import { BOOYONG_COMPANY_ID, STORAGE_COMPANY_IDS } from '@/constants/general'

const { start, end } = getWeekBounds()

const state = {
  stockList: [],
  stockDestinationList: [],
  stockDestinationListFull: [],
  loadingStock: '',

  //pagination
  totalStock: 0,
  perPageStock: PER_PAGE_OPTIONS[0],
  pageNrStock: 0,

  //sorting
  sortbyStock: 'plantlog',
  ascStock: false,

  //filters
  filtersStock: {
    prod_date_start: { type: 'date', label: 'Start Date', value: start },
    prod_date_end: { type: 'date', label: 'End Date', value: end },
    docket: { type: 'text', label: 'Docket Number', value: '' },
    product_name: { type: 'text', label: 'Product Name', value: '' },
    product_code: { type: 'text', label: 'Code', value: '' },
    barcode: { type: 'text', label: 'Barcode', value: '' },
    destination_co_id: {
      type: 'select',
      name: 'company_id',
      label: 'Location',
      selectLabel: 'name',
      value: '',
      customOptions: (option) => {
        return `${option.code} - ${option.name}`
      }
    },
    status: {
      type: 'select',
      label: 'Status',
      value: '',
      options: ['INPLANT', 'INSTORAGE', 'DELIVERED', 'EXPIRED']
    },
    week: { type: 'week', label: 'Week Nr', value: getISOWeekNumber() },
    year: { type: 'year', value: getYear(), hidden: true }
  },
  sortMapStock: {
    plantlog: 'plantlog:proddate'
  }
}
const getters = {
  stockList: (state) => state.stockList,
  stockDestinationList: (state) => state.stockDestinationList,
  stockDestinationListFull: (state) => state.stockDestinationListFull,
  locationCustomer: (state) => {
    return state.stockDestinationList.filter(
      (dest) => dest.type.toLowerCase() === 'customer'
    )
  },
  locationBoning: (state) => {
    return state.stockDestinationListFull.filter(
      (dest) => dest.type.toLowerCase() === 'boning'
    )
  },
  locationStorage: (state) => {
    return state.stockDestinationList.filter(
      (dest) => dest.type.toLowerCase() === 'storage'
    )
  },
  loadingStock: (state) => state.loadingStock,
  totalStock: (state) => state.totalStock,
  perPageStock: (state) => state.perPageStock,
  pageNumberStock: (state) => state.pageNrStock,
  sortbyStock: (state) => state.sortbyStock,
  sortMapStock: (state) => state.sortMapStock,
  ascStock: (state) => state.ascStock,
  filtersStock: (state) => state.filtersStock,
  offsetStock: (state) => {
    return state.perPageStock * state.pageNrStock
  },
  totalStockWeight: (state) => {
    if (state.stockList.length === 0) return 0

    const totalWeight = state.stockList.reduce((total, item) => {
      return item.weight ? total + item.weight : total
    }, 0)
    return totalWeight
  },
  stockSummary: (state) => {
    if (state.stockList.length === 0) return {}

    const summary = state.stockList.reduce((summary, item) => {
      const destination = item?.tracklog?.destination || 'UNDEFINED'
      const docket = item?.tracklog?.docket || 'NONE'
      if (!Object.prototype.hasOwnProperty.call(summary, destination))
        summary[destination] = {}
      if (!Object.prototype.hasOwnProperty.call(summary[destination], docket))
        summary[destination][docket] = { totalWeight: 0, qty: 0 }
      if (
        !Object.prototype.hasOwnProperty.call(
          summary[destination][docket],
          item.code
        )
      ) {
        summary[destination][docket].totalWeight += item.weight
        summary[destination][docket].qty += 1
        summary[destination][docket][item.code] = {
          name: item.product,
          qty: 1,
          totalWeight: item.weight,
          customer: destination,
          productCode: item.code
        }
        return summary
      }
      summary[destination][docket].totalWeight += item.weight
      summary[destination][docket].qty += 1
      summary[destination][docket][item.code].qty += 1
      summary[destination][docket][item.code].totalWeight += item.weight
      return summary
    }, {})
    return summary
  }
}
const actions = {
  async fetchStock({ commit, dispatch, getters }) {
    const endpoint = 'product/'
    commit('SET_LOADING_STOCK', 'list')

    let sortBy = getters.sortMapStock[getters.sortbyStock]
    sortBy = [
      getters.ascStock ? sortBy : `${sortBy}|-`,
      'BooyongProductList.code'
    ].join(',')

    const parameters = {
      limit: getters.perPageStock,
      offset: getters.offsetStock,
      sort_by: sortBy
    }
    const filters = getters.filtersStock

    let { error, data, total } = await dispatch(
      'fetch',
      { endpoint, parameters, filters },
      { root: true }
    )

    if (!error) {
      const result = await Promise.all(
        data.map(async (item) => {
          const destructedStockItem = await dispatch('destructStockItem', item)
          return destructedStockItem
        })
      )
      commit('SET_STOCK_LIST', result)
      commit('SET_TOTAL_STOCK', total)
    } else {
      const messagePrefix = 'Error while loading product stock! '
      dispatch('showError', { error, messagePrefix })
    }

    commit('SET_LOADING_STOCK', '')
  },
  async destructStockItem(attr, payload) {
    const {
      specification,
      product_desc,
      product_desc: { product },
      ...rest
    } = payload
    return {
      ...specification,
      product_desc,
      product,
      ...rest
    }
  },
  async fetchProductDestinations({ rootGetters, commit, dispatch }) {
    const URI = rootGetters.baseUrl + 'product/destinationlist'
    try {
      commit('SET_LOADING_STOCK', 'destination')
      let { data } = await axios.get(URI)
      commit('SET_STOCK_DESTINATION_LIST_FULL', data.data)

      const destinationList = data.data.filter(
        (item) =>
          !(item.company_id === BOOYONG_COMPANY_ID && item.type === 'Boning') &&
          !(
            STORAGE_COMPANY_IDS.includes(item.company_id) &&
            item.type === 'Customer'
          )
      )
      commit('SET_STOCK_DESTINATION_LIST', destinationList)
    } catch (error) {
      const messagePrefix = 'Error while loading stock destination list! '
      dispatch('showError', { error, messagePrefix })
    } finally {
      commit('SET_LOADING_STOCK', '')
    }
  },

  async updateStockItem({ rootGetters, commit, dispatch }, payload) {
    const { id, barcode } = payload
    const URI = rootGetters.baseUrl + 'product/' + id

    try {
      commit('SET_LOADING_STOCK', `update-${id}`)
      const res = await axios.patch(URI, payload)

      const message = `Stock Item: ${barcode} updated successfuly!`
      dispatch('showMessage', message)

      return res.data
    } catch (error) {
      const messagePrefix = 'Update Stock Item Error. '
      dispatch('showError', { error, messagePrefix })

      return 'error'
    } finally {
      commit('SET_LOADING_STOCK', '')
    }
  },
  async bulkUpdateStockItem({ rootGetters, commit, dispatch }, payload) {
    const URI = rootGetters.baseUrl + 'product/batch_update/'
    try {
      commit('SET_LOADING_STOCK', 'bulk-update')
      const { data } = await axios.patch(URI, payload)
      dispatch('showMessage', `Stock items updated successfuly!`)

      return data
    } catch (error) {
      dispatch('showError', { error })
      return 'error'
    } finally {
      commit('SET_LOADING_STOCK', '')
    }
  },
  async bulkUpdateStockStatus({ rootGetters, dispatch, commit }, payload) {
    const URI = rootGetters.baseUrl + 'product/bulk_update_status'
    try {
      commit('SET_LOADING_STOCK', 'bulk-update-scanned')
      let { data } = await axios.post(URI, payload)

      const message = 'Stok Items has been sucessfully processed'
      dispatch('showMessage', message)

      return data
    } catch (error) {
      const messagePrefix = 'Stock Items Procssing Error. '
      dispatch('showError', { error, messagePrefix })

      return 'error'
    } finally {
      commit('SET_LOADING_STOCK', '')
    }
  },

  clearFiltersStock({ commit }) {
    commit('SET_FILTER_STOCK', { field: 'prod_date_start', value: '' })
    commit('SET_FILTER_STOCK', { field: 'prod_date_end', value: '' })
    commit('SET_FILTER_STOCK', { field: 'product_name', value: '' })
    commit('SET_FILTER_STOCK', { field: 'product_code', value: '' })
    commit('SET_FILTER_STOCK', { field: 'barcode', value: '' })
    commit('SET_FILTER_STOCK', { field: 'docket', value: '' })
    commit('SET_FILTER_STOCK', { field: 'destination_co_id', value: '' })
    commit('SET_FILTER_STOCK', { field: 'status', value: '' })
    commit('SET_FILTER_STOCK', { field: 'week', value: null })
  }
}
const mutations = {
  SET_STOCK_LIST: (state, payload) => (state.stockList = payload),
  SET_STOCK_DESTINATION_LIST: (state, payload) =>
    (state.stockDestinationList = payload),
  SET_STOCK_DESTINATION_LIST_FULL: (state, payload) =>
    (state.stockDestinationListFull = payload),
  SET_LOADING_STOCK: (state, payload) => (state.loadingStock = payload),

  UPDATE_STOCK_LIST: (state, { index, stock }) => {
    state.stockList = [
      ...state.stockList.slice(0, index),
      stock,
      ...state.stockList.slice(index + 1)
    ]
  },
  SET_TOTAL_STOCK: (state, payload) => (state.totalStock = payload),
  SET_PAGE_NR_STOCK: (state, payload) => (state.pageNrStock = payload),
  SET_PER_PAGE_STOCK: (state, payload) => (state.perPageStock = payload),
  SET_ASC_STOCK: (state, payload) => (state.ascStock = payload),
  SET_SORT_BY_STOCK: (state, payload) => (state.sortbyStock = payload),
  SET_FILTER_STOCK: (state, { field, value }) => {
    if (state.filtersStock[field].type !== 'select') {
      state.filtersStock[field].value = value
      return
    }

    if (value) {
      state.filtersStock[field].value = value
      return
    }
    state.filtersStock[field].value = ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
