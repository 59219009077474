<script setup>
import { useStore } from '@/utils/VuexHelpers'
import {
  computed,
  getCurrentInstance,
  ref,
  defineProps,
  defineEmits,
  watch
} from 'vue'

const vm = getCurrentInstance().proxy
const emit = defineEmits(['update:destination'])
const props = defineProps({
  destination: Number,
  label: {
    type: String,
    default: 'Destination'
  }
})

const store = useStore()
const destinationList = computed(() => {
  const copy = JSON.parse(JSON.stringify(store.getters.destinationList))
  return copy.sort((a, b) => a.name.localeCompare(b.name))
})
const loadingTransport = computed(() => store.getters.loadingTransport)

const value = ref(null)
const isLoading = computed(() => loadingTransport.value === 'destination')

const customFilter = (item, queryText) => {
  const textOne = item.name.toLowerCase()
  const textTwo = item.code ? item.code.toLowerCase() : ''
  const searchText = queryText.toLowerCase().trim()

  return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
}

const updateValue = (value) => {
  emit('update:destination', value)
}

watch(
  () => props.destination,
  (newValue) => {
    if (value.value === newValue) return
    value.value = newValue
  },
  { immediate: true }
)

// ON CREATED
if (destinationList.value.length === 0 && !isLoading.value) {
  store.dispatch('fetchDestinations', { vm })
}
</script>

<template>
  <v-autocomplete
    v-model="value"
    :items="destinationList"
    :filter="customFilter"
    item-text="name"
    item-value="company_id"
    outlined
    dense
    :label="label"
    :loading="isLoading"
    hide-details
    class="my-1"
    @change="updateValue"
  >
    <template v-slot:selection="{ item, selected, attrs }">
      <div v-bind="attrs" :input-value="selected">
        {{ `${item.code} - ${item.name}` }}
      </div>
    </template>
    <template v-slot:item="data">
      <template v-if="typeof data.item !== 'object'">
        <v-list-item-content v-text="data.item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content class="text-start">
          <v-list-item-title>
            {{ `${data.item.code} - ${data.item.name}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>

<style></style>
