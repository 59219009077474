<script setup>
import BaseButton from '@/components/BaseButton.vue'
</script>

<template>
  <BaseButton
    class=""
    variant="warning"
    size="sm"
    :disabled="
      scannedItems.length === 0 || loadingStock === 'bulk-update-scanned'
    "
    @click.native="updateStockStatus()"
    :loading="loadingStock === 'bulk-update-scanned'"
    :icon="actionIcon"
    >Process Items</BaseButton
  >
</template>

<script>
import { getCurrentDay } from '@/utils/DateHelpers'
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'ProductProcessButton',
  props: {
    scannedItems: {
      type: Array,
      required: true
    },
    action: {
      type: String,
      required: true
    }
  },
  data() {
    return {}
  },
  computed: {
    ...mapGetters(['loadingStock']),
    actionIcon() {
      switch (this.action) {
        case 'DELIVERED':
          return ['fas', 'truck-arrow-right']
        case 'INSTORAGE':
          return ['fas', 'fa-box-open']
        case 'EXPIRED':
          return ['far', 'trash-alt']
        default:
          return ['fas', 'truck-arrow-right']
      }
    }
  },
  methods: {
    ...mapActions(['bulkUpdateStockStatus', 'fetchStock']),
    updateStockStatus: async function () {
      const productIdList = this.scannedItems.map((item) => {
        return {
          barcode: item.barcode,
          docket: item.docket,
          destination: item.location
        }
      })
      const payload = {
        status: this.action,
        date: getCurrentDay(),
        logisticlogs: productIdList
      }
      let res = await this.bulkUpdateStockStatus(payload)
      if (res !== 'error') {
        this.$emit('clearScannedItems')
        this.fetchStock()
      }
    }
  }
}
</script>
