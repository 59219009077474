<script setup>
import SideFilter from '@/components/SideFilter.vue'

import useWeekDateFilter from '@/utils/useWeekDateFilter'
import { computed, ref } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { WEEK_STR, YEAR_STR } from '@/constants/general'

const DATE_START_STR = 'prod_date_start'
const DATE_END_STR = 'prod_date_end'

const stockSideFilter = ref(null)

const store = useStore()
const filtersStock = computed(() => store.getters.filtersStock)

const { updateDateFilter } = useWeekDateFilter(
  'filtersStock',
  'SET_FILTER_STOCK',
  DATE_START_STR,
  DATE_END_STR
)

const updateFilter = ({ value, field }) => {
  if (field === DATE_START_STR || field === DATE_END_STR) {
    updateDateFilter({ value, field })
  } else {
    store.commit('SET_FILTER_STOCK', { value, field })
  }

  store.dispatch('fetchStock')
}

const removeFilters = () => {
  store.dispatch('clearFiltersStock')
  store.dispatch('fetchStock')
}

const updateWeekFilter = (dateRange) => {
  const { weekNr, startDay, endDay, year } = dateRange
  const filters = [
    { field: WEEK_STR, value: weekNr },
    { field: DATE_START_STR, value: startDay },
    { field: DATE_END_STR, value: endDay },
    { field: YEAR_STR, value: year }
  ]

  filters.forEach((filter) => store.commit('SET_FILTER_STOCK', filter))
  store.dispatch('fetchStock')
}

const updateYearFilter = ({ year: inputYear }) => {
  const { year, week } = filtersStock.value
  const cleanYear = parseInt(inputYear.trim())
  if (cleanYear === year.value) return

  store.commit('SET_FILTER_STOCK', { field: YEAR_STR, value: cleanYear })

  if (week.value) {
    stockSideFilter.value.generateWeekDays(week.value, cleanYear)
  }
}
</script>

<template>
  <SideFilter
    ref="stockSideFilter"
    :filter-list="filtersStock"
    @input="updateFilter"
    @inputWeek="updateWeekFilter"
    @inputYear="updateYearFilter"
    @clear="removeFilters"
    class="mt-0"
  />
</template>
