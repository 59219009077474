<script setup>
import { defineProps } from 'vue'

defineProps(['items'])

const triggerClick = (triggerAction) => {
  if (!triggerAction) return

  triggerAction()
}
</script>

<template>
  <v-menu offset-y content-class="mt-1">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon variant="text" v-bind="attrs" v-on="on" size="small"
        ><v-icon> mdi-dots-vertical </v-icon></v-btn
      >
    </template>

    <v-list dense>
      <slot v-for="(item, i) in items" :name="`item-${item.key}`" :item="item">
        <v-list-item
          :key="i"
          @click="() => triggerClick(item.action)"
          class="bsp-list-item"
        >
          <v-list-item-icon v-if="item.icon">
            <v-icon v-text="item.icon" dense></v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title
              v-text="item.title"
              class="text-body-1 text-start"
            ></v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </slot>
    </v-list>
  </v-menu>
</template>

<style>
.bsp-list-item > .v-list-item__icon {
  margin-right: 12px !important;
}
</style>
