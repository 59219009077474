<script setup>
import { defineProps, defineEmits } from 'vue'
import BaseDateSelect from './BaseDateSelect.vue'

defineProps(['field', 'value'])
const emit = defineEmits(['input'])

const determineComponent = (field) => {
  switch (field.type) {
    case 'text':
      return 'v-text-field'
    case 'number':
      return 'v-text-field'
    case 'select':
      return 'v-select'
    case 'file':
      return 'v-file-input'
    default:
      return 'v-text-field'
  }
}
const updateValue = (event) => {
  emit('input', event)
}
</script>

<template>
  <div v-if="field.type === 'text-chips'">
    <v-text-field
      :value="value"
      @change="updateValue"
      :rules="field.rules"
      :label="field.label"
      :placeholder="field.placeholder"
      hide-details="auto"
    ></v-text-field>
    <div class="d-flex my-2">
      <v-chip
        v-for="(chip, index) in field.chips"
        small
        class="mr-2"
        :key="index"
        @click="() => updateValue(chip)"
      >
        {{ chip }}
      </v-chip>
    </div>
  </div>
  <div v-else-if="field.type === 'date'" class="my-4" :class="field.class">
    <BaseDateSelect
      :label="field.label"
      :date="value"
      :minDate="field.minDate"
      @update:date="updateValue"
    />
  </div>
  <div v-else-if="field.type === 'checkbox'" class="my-2" :class="field.class">
    <v-checkbox
      :label="field.label"
      :input-value="value"
      @change="updateValue"
      hide-details="auto"
    ></v-checkbox>
  </div>
  <div
    v-else
    class="align-center"
    :class="field.dense === true ? 'my-4 pb-2' : 'my-2'"
  >
    <component
      :is="determineComponent(field)"
      :value="value"
      @change="updateValue"
      :label="field.label"
      :rules="field.rules"
      :hint="field.hint"
      :placeholder="field.placeholder"
      :type="field.type"
      :items="field.items"
      :multiple="field.multiple"
      :min="field.min"
      :step="field.step"
      :accept="field.accept"
      :prefix="field.prefix"
      :class="field.class"
      hide-details="auto"
      :outlined="field.variant === 'outlined'"
      :dense="field.dense === true"
    >
      <v-icon v-if="field.prepend" slot="prepend" color="primary">
        {{ field.prepend }}
      </v-icon>
    </component>
  </div>
</template>
