import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

import userInfo from './modules/userInfo'
import filesUploadApi from './modules/filesUploadApi'
import ordersApi from './modules/ordersApi'
import customersApi from './modules/customersApi'
import suppliersApi from './modules/suppliersApi'
import transportApi from './modules/transportApi'
import pigInfoApi from './modules/pigInfoApi'
import boningApi from './modules/boningApi'
import loadoutApi from './modules/loadoutApi'
import productApi from './modules/productApi'
import pigPartApi from './modules/pigPartApi'
import stockApi from './modules/stockApi'
import systemSettingsApi from './modules/systemSettingsApi'
import reconcileOrdersApi from './modules/reconcileOrdersApi'
import processingCostsReportApi from './modules/processingCostsReportApi'
import loadoutReportApi from './modules/loadoutReportApi'
import growerReportApi from './modules/growerReportApi'
import processingPriceApi from './modules/processingPriceApi'
import notificationStore from './modules/notificationStore'

import { PER_PAGE_OPTIONS } from '@/constants/pagination'
import { formatDate } from '@/helpers/stringHelpers'
import { getISOWeekNumber } from '@/utils/DateHelpers'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    userInfo,
    filesUploadApi,
    ordersApi,
    customersApi,
    transportApi,
    suppliersApi,
    pigInfoApi,
    boningApi,
    loadoutApi,
    productApi,
    pigPartApi,
    stockApi,
    systemSettingsApi,
    reconcileOrdersApi,
    processingCostsReportApi,
    processingPriceApi,
    loadoutReportApi,
    growerReportApi,
    notificationStore
  },
  state: {
    baseUrl: process.env.VUE_APP_API_URL,
    serverType: process.env.VUE_APP_SERVER_TYPE,
    perPageOptions: PER_PAGE_OPTIONS,
    productCategories: [
      'Hindquarter Cuts',
      'Forequarter Cuts',
      'Middle Cuts',
      'Other'
    ],
    plantDays: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'],
    parPriceTypes: ['default', 'SUK', 'SP', 'BP'],
    pigBodyTypes: ['SUK', 'BP', 'SP', 'MHP', 'MLP', 'GHP', 'BAHP', 'BLP'],
    processingCostTypes: [
      'SUK',
      'SP',
      'BP',
      'XSMALL',
      'SMALL',
      'MEDIUM',
      'LARGE',
      'XLARGE',
      'GRADING',
      'LEVY'
    ],
    weightRequiredCostTypes: ['XSMALL', 'SMALL', 'MEDIUM', 'LARGE', 'XLARGE'],
    processingCostSections: ['Slaughter', 'Government Levies'],
    processingCostUnit: ['EA', 'KG', 'BDY'],
    stockStatusOptions: ['INPLANT', 'INSTORAGE', 'DELIVERED', 'EXPIRED'],
    active: '',
    usingVuetify: false,
    procFees: {
      KILL: {
        SUK: {
          fee: {
            default: 36.03,
            '2022-10-03': 38.01
          },
          type: 'SUK',
          text: 'Kill Fee Suckling Pigs (SUK)'
        },
        SP: {
          fee: {
            default: 83.31
          },
          type: 'SP',
          text: 'Kill Fee Back Fatters (SP)'
        },
        BP: {
          fee: {
            default: 83.31
          },
          type: 'BP',
          text: 'Kill Fee Back Fatters (BP)'
        },
        XSMALL: {
          fee: {
            default: 28.97
          },
          min: 0,
          max: 55,
          text: 'Kill Fee Pigs Up To 55kg'
        },
        SMALL: {
          fee: {
            default: 30.73,
            '2022-10-03': 32.42
          },
          min: 55,
          max: 80,
          text: 'Kill Fee Pigs 55.1 to 80kg'
        },
        MEDIUM: {
          fee: {
            default: 34.38,
            '2022-10-03': 36.27
          },
          min: 80,
          max: 95,
          text: 'Kill Fee Pigs 80.1 to 95kg'
        },
        LARGE: {
          fee: {
            default: 41.71,
            '2022-10-03': 44.0
          },
          min: 95,
          max: 105,
          text: 'Kill Fee Pigs 95.1 to 105kg'
        },
        XLARGE: {
          fee: {
            default: 49.96
          },
          min: 105,
          max: 1000,
          text: 'Kill Fee Pigs 105.1kg+'
        }
      },
      GRADING: {
        fee: {
          default: 0.017,
          '2022-10-03': 0.018
        },
        text: 'Pork Grading Fee'
      },
      LEVY: {
        fee: {
          default: 3.425
        },
        text: 'Transaction Levy - Pig'
      }
    }
  },
  getters: {
    isProdServer: (state) => state.serverType === 'prod',
    baseUrl: (state) => state.baseUrl,
    perPageOptions: (state) => state.perPageOptions,
    productCategories: (state) => state.productCategories,
    active: (state) => state.active,
    usingVuetify: (state) => state.usingVuetify,
    plantDays: (state) => state.plantDays,
    pigBodyTypes: (state) => state.pigBodyTypes,
    parPriceTypes: (state) => state.parPriceTypes,
    processingCostTypes: (state) => state.processingCostTypes,
    processingCostSections: (state) => state.processingCostSections,
    processingCostUnit: (state) => state.processingCostUnit,
    weightRequiredCostTypes: (state) => state.weightRequiredCostTypes,
    stockStatusOptions: (state) => state.stockStatusOptions
  },
  mutations: {
    SET_ACTIVE: (state, payload) => (state.active = payload),
    SET_USING_VUETIFY: (state, payload) => (state.usingVuetify = payload)
  },
  actions: {
    async fetch({ getters, dispatch }, { endpoint, parameters, filters }) {
      const filt = await dispatch('getFilters', filters)
      try {
        let { data } = await axios.get(getters.baseUrl + endpoint, {
          params: { ...filt, ...parameters }
        })
        return data
      } catch (error) {
        return { error }
      }
    },
    async handleError(context, { vm, error, title, messagePrefix = '' }) {
      let message = error.message

      if (error.response?.data?.message) {
        message = error.response.data.message
      }
      if (error.response?.data?.detail) {
        if (
          typeof error.response.data.detail === 'string' ||
          typeof error.response.data.detail === 'number'
        ) {
          message = error.response.data.detail
        } else {
          message = error.response.data.detail[0].msg
        }
      }
      if (error.error !== 'Refresh token expired') {
        vm.$bvToast.toast(messagePrefix + message, {
          title: title,
          variant: 'danger',
          solid: true,
          noAutoHide: true
        })
      }
      console.log(error)
    },
    async getFilters(context, filters) {
      return Object.fromEntries(
        Object.entries(filters).reduce((result, [key, value]) => {
          if (['text', 'date', 'select'].includes(value.type)) {
            let v = value.value
            if (typeof value.value !== 'number') v = v.trim()
            if (v) result.push([key, v])
          }
          if (value.type == 'checkbox') {
            if (value.value.length === 1) {
              result.push([key, value.value[0]])
            }
          }
          return result
        }, [])
      )
    },
    async getWeeks(context, { dataDate }) {
      const weekday = [
        'SUNDAY',
        'MONDAY',
        'TUESDAY',
        'WEDNESDAY',
        'THURSDAY',
        'FRIDAY',
        'SATURDAY'
      ]

      const d = new Date(dataDate)
      const startDate = formatDate(dataDate)
      const weekNr = getISOWeekNumber(d)
      const day = weekday[d.getDay()]

      return [startDate, weekNr, day]
    }
  }
})
