var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"scrollable":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":"","color":"info","disabled":_vm.stockItems.length === 0}},'v-btn',attrs,false),on),[_c('span',{staticClass:"d-flex align-center"},[_c('font-awesome-icon',{staticClass:"mr-1",attrs:{"icon":['fas', 'edit']}}),_vm._v("Batch Edit")],1)])]}}]),model:{value:(_setup.dialog),callback:function ($$v) {_setup.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-text',{staticClass:"pt-4"},[_c('h6',{staticClass:"text-button text-light-blue text-start"},[_vm._v(" Batch Update Stock Items ")]),_c('v-form',{ref:"batchStockForm",on:{"submit":function($event){$event.preventDefault();return _setup.submitForm.apply(null, arguments)}},model:{value:(_setup.isFormValid),callback:function ($$v) {_setup.isFormValid=$$v},expression:"isFormValid"}},[_c(_setup.BaseInputField,{attrs:{"field":_setup.fields[0],"value":_setup.formData[_setup.fields[0].key]},on:{"input":(val) => {
              _setup.formData[_setup.fields[0].key] = val
            }}}),(_setup.formData[_setup.fields[0].key].toLowerCase() !== 'expired')?_c(_setup.BaseSelectStockDestination,{attrs:{"destination":_setup.formData.location,"stock-status":_setup.formData[_setup.fields[0].key],"required":true,"label":"Location"},on:{"update:destination":(val) => {
              _setup.formData.location = val
            }}}):_vm._e(),_c('v-checkbox',{staticClass:"font-italic font-weight-medium mt-4 mb-2 pt-0",attrs:{"label":"Update Docket Number","x-small":"","hide-details":""},model:{value:(_setup.enableDocket),callback:function ($$v) {_setup.enableDocket=$$v},expression:"enableDocket"}}),(_setup.enableDocket)?_c(_setup.BaseInputField,{attrs:{"field":_setup.fields[1],"value":_setup.formData[_setup.fields[1].key]},on:{"input":(val) => {
              _setup.formData[_setup.fields[1].key] = val
            }}}):_vm._e(),_c('v-row',{staticClass:"justify-end",attrs:{"no-gutters":""}},[_c('v-btn',{staticClass:"me-2",attrs:{"color":"error","text":""},on:{"click":_setup.resetForm}},[_vm._v("Reset")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_setup.loadingStock === 'bulk-update'}},[_vm._v("Submit")])],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }