<script setup>
import IconDelete from '../IconDelete.vue'
import FixBodyTypeModal from '../FixBodyTypeModal.vue'
import AllocateDialog from '@/components/pig-info-page/AllocateDialog.vue'
import ClearAllocations from '@/components/pig-info-page/ClearAllocations.vue'
import BaseFreightCodeDisplay from '@/components/base/BaseFreightCodeDisplay.vue'
import ExportPigsByTransport from './ExportPigsByTransport.vue'
import DeletePigs from './DeletePigs.vue'

import { computed, getCurrentInstance, ref } from 'vue'
import { useStore } from '@/utils/VuexHelpers'
import { formatDate, formatCurrency } from '@/helpers/stringHelpers'
import { checkBodyTypeError } from '@/utils/Validators.js'

const store = useStore()
const loadingPigInfo = computed(() => store.getters.loadingPigInfo)
const loadingAllocations = computed(() => store.getters.loadingAllocations)
const pigInfoList = computed(() => {
  if (loadingPigInfo.value === 'list') return []
  return store.getters.pigInfoList
})
const perPagePigInfo = computed(() => store.getters.perPagePigInfo)
const sortBy = computed(() => store.getters.sortbyPig)
const sortDesc = computed(() => !store.getters.ascPig)

const vm = getCurrentInstance().proxy
const editPigModal = ref(null)
const selectedPigs = ref([])
const expanded = ref([])

const headers = [
  { value: 'date', text: 'Date', sortable: true },
  { value: 'body_no', text: 'Body Nr.', sortable: true },
  { value: 'p2', text: 'P2', sortable: true },
  { value: 'sex', text: 'Sex', sortable: true },
  { value: 'weight', text: 'Weight', sortable: false },
  { value: 'yield', text: 'Yield', sortable: true },
  { value: 'producer', text: 'Grower', sortable: false },
  { value: 'carcase_type', text: 'Carcase Type', sortable: true },
  { value: 'status', text: 'Status', sortable: false },
  { value: 'allocation', text: 'Allocation - Destination', sortable: false },
  { value: 'data-table-expand' },
  { value: 'actions', text: 'Actions', sortable: false }
]

const sortChange = ({ sortBy, sortDesc }) => {
  store.commit('SET_ASC_PIG', !sortDesc[0])
  store.commit('SET_SORT_BY_PIG', sortBy[0])
  store.dispatch('fetchPigInfo', { vm })
}

const resetSelected = () => {
  selectedPigs.value = []
}

const calculateYield = (info) => {
  if (info.CSCW && info.HSCW) {
    let total = (parseFloat(info.CSCW) / parseFloat(info.HSCW)) * 100
    return total.toFixed(2) + '%'
  }
  return '-'
}

const deleteAlloc = ({ info, pigIndex, index }) => {
  vm.$bvModal
    .msgBoxConfirm(
      `Are you sure you want to delete? ${info.part.part}: ${info.destination.name}`,
      {
        buttonSize: 'sm',
        size: 'sm',
        okTitle: 'Yes',
        cancelTitle: 'No'
      }
    )
    .then(async (value) => {
      if (value) {
        let res = await store.dispatch('deleteAllocation', {
          vm,
          payload: info
        })
        if (res === 'success') {
          pigInfoList.value[pigIndex].allocation.splice(index, 1)
        }
      }
    })
    .catch((err) => {
      console.log(err)
    })
}

const updateBodyAllocation = (alloc) => {
  selectedPigs.value[0].allocation = alloc
  resetSelected()
}
const clearAllocations = () => {
  selectedPigs.value.forEach((pig) => (pig.allocation = []))
}
const getRowClass = (item) => {
  if (!checkBodyTypeError(item)) return ''
  return 'red lighten-4'
}
</script>

<template>
  <v-container class="mx-0 mw-100">
    <!-- MODALS -->
    <FixBodyTypeModal ref="editPigModal" />
    <!-- end MODALS -->

    <v-data-table
      id="pig-info-table"
      v-model="selectedPigs"
      :items="pigInfoList"
      :headers="headers"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:options="sortChange"
      @current-items="resetSelected"
      must-sort
      fixed-header
      height="800"
      class="w-100 elevation-2"
      :loading="loadingPigInfo === 'list'"
      loading-text="Loading... Please wait"
      :show-select="true"
      show-expand
      single-expand
      :expanded.sync="expanded"
      :item-class="getRowClass"
      :server-items-length="perPagePigInfo"
      hide-default-footer
    >
      <template v-slot:top>
        <v-row class="justify-start align-center p-2 pt-0 bg-light-blue"
          ><v-col cols="auto"
            ><small class="text-uppercase"
              >Selected Pigs - {{ selectedPigs.length }}</small
            ></v-col
          >
          <v-col cols="auto">
            <AllocateDialog
              :pigItems="selectedPigs"
              @update:pigItems="updateBodyAllocation"
              @updateComplete="resetSelected"
            />
          </v-col>
          <v-col cols="auto">
            <ClearAllocations
              :pigItems="selectedPigs"
              @clearSuccess="clearAllocations"
            />
          </v-col>
          <v-col cols="auto" class="ms-auto">
            <ExportPigsByTransport />
          </v-col>
          <v-col cols="auto" class="ps-0 pe-1">
            <DeletePigs :pig-items="selectedPigs" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td :colspan="headers.length">
          <v-row>
            <v-col cols="auto">
              Pig <b class="me-2">{{ item.body_no }}</b>
              <span
                :class="
                  item.customValue !== undefined ? 'yellow lighten-4' : ''
                "
                >Value:
                {{
                  item.customValue === undefined
                    ? formatCurrency(item.value)
                    : formatCurrency(item.customValue)
                }}</span
              >
            </v-col>
            <v-col cols="auto">Cat: {{ item.cat ? item.cat : '-' }}</v-col>
          </v-row>
        </td>
      </template>
      <template v-slot:item.date="{ value }">
        {{ formatDate(value) }}
      </template>
      <template v-slot:item.body_no="{ item }">
        <div :id="`${item.body_no}-${item.date}`">{{ item.body_no }}</div>
        <v-chip
          v-if="item.condems !== ''"
          label
          x-small
          outlined
          color="red"
          class="status-chip white--text px-1"
          >Damaged</v-chip
        >
      </template>
      <template v-slot:item.value="{ value }">
        {{ formatCurrency(value) }}
      </template>
      <template v-slot:item.weight="{ item }">
        <v-row class="flex-column" no-gutters>
          <v-col class="text-nowrap">Hot: {{ item.HSCW }}kg</v-col>
          <v-col v-if="item.CSCW" class="text-nowrap" no-gutters>
            Cold: {{ item.CSCW }}kg
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.yield="{ item }">
        <span class="text-nowrap">{{ calculateYield(item) }} </span>
      </template>
      <template v-slot:item.producer="{ value }">
        {{ value.code }}
      </template>
      <template v-slot:item.status="{ value }">
        <v-chip label x-small :class="value" class="status-chip">{{
          value
        }}</v-chip>
      </template>
      <template v-slot:item.allocation="{ item, index }">
        <span v-if="item.allocation">
          <v-row
            no-gutters
            v-if="loadingAllocations.includes(item.id.toString())"
          >
            <b-spinner class="mr-2" small />
            <span>Saving...</span>
          </v-row>
          <span v-else>
            <div v-for="(alloc, allocIdx) in item.allocation" :key="alloc.id">
              <v-row
                no-gutters
                class="text-nowrap align-center"
                :class="{
                  'mt-1': allocIdx === 0,
                  'mb-1': allocIdx === item.allocation.length - 1
                }"
              >
                <IconDelete
                  @click.native="
                    deleteAlloc({
                      info: alloc,
                      pigIndex: index,
                      index: allocIdx
                    })
                  "
                  class="text-muted me-2"
                />
                <span class="font-weight-bold mr-2"
                  >{{ alloc.part.part }}:</span
                >
                <span>{{ alloc.destination.name }}</span>
                <v-chip class="ms-2" color="primary" outlined pill small>
                  <font-awesome-icon
                    icon="fas fa-truck-arrow-right"
                    class="me-2"
                  ></font-awesome-icon
                  ><BaseFreightCodeDisplay :freightIds="alloc.transport_id" />
                </v-chip>
              </v-row>
              <v-divider
                v-if="item.allocation.length > allocIdx + 1"
                class="my-1"
              ></v-divider>
            </div>
          </span>
        </span>
      </template>
      <template v-slot:item.actions="{ index, item }">
        <v-btn
          x-small
          class="my-1"
          @click="
            editPigModal.updateBodyType({
              index: index,
              ...item
            })
          "
          ><font-awesome-icon :icon="['fas', 'edit']" class="mr-2" />Edit</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
.status-chip {
  font-weight: 700;
}
.Unallocated {
  background-color: #fff2cc !important;
  color: #993300 !important;
}
.Broken {
  background-color: #ffcc00 !important;
  color: #ff2e00 !important;
}
.BONING {
  background-color: #66ccff !important;
  color: #ffffff !important;
}
.Butcher {
  background-color: #ff99cc !important;
  color: #ffffff !important;
}
.MISTAKE {
  background-color: #ff2e00 !important;
  color: #ffffff !important;
}
</style>
