import axios from 'axios'
import { PER_PAGE_OPTIONS } from '@/constants/pagination'
import {
  getYear,
  getISOWeekday,
  formatDateWithAddedDays,
  getWeekBounds,
  getISOWeekNumber
} from '@/utils/DateHelpers'

const { start, end } = getWeekBounds()

const state = {
  pigInfoList: [],
  loadingPigInfo: '',
  loadingAllocations: [],

  //reports
  pigInfoTotals: {
    WholePig: 0,
    Trunk: 0,
    Haunch: 0,
    Forequarter: 0,
    Saddle: 0,
    Leg: 0,
    HalfPig1: 0,
    HalfPig2: 0
  },
  runReport: {
    completed: {
      pigs: [],
      totals: {
        WholePig: 0,
        Trunk: 0,
        Haunch: 0,
        Forequarter: 0,
        Saddle: 0,
        Leg: 0,
        HalfPig1: 0,
        HalfPig2: 0
      }
    },
    incomplete: {
      pigs: [],
      totals: {
        WholePig: 0,
        Trunk: 0,
        Haunch: 0,
        Forequarter: 0,
        Saddle: 0,
        Leg: 0,
        HalfPig1: 0,
        HalfPig2: 0
      }
    },
    boning: {
      pigs: [],
      totals: {
        WholePig: 0,
        Trunk: 0,
        Haunch: 0,
        Forequarter: 0,
        Saddle: 0,
        Leg: 0,
        HalfPig1: 0,
        HalfPig2: 0
      }
    },
    breakBoning: {
      pigs: [],
      totals: {
        WholePig: 0,
        Trunk: 0,
        Haunch: 0,
        Forequarter: 0,
        Saddle: 0,
        Leg: 0,
        HalfPig1: 0,
        HalfPig2: 0
      }
    },
    unalloc: {
      pigs: []
    }
  },

  //pagination
  totalPig: 0,
  perPagePig: PER_PAGE_OPTIONS[0],
  pageNrPig: 0,

  //date
  startDatePig: '',
  startDayPig: '',
  startWeekPig: '',

  //sorting
  sortbyPig: 'date',
  multiSortbyPig: [],
  ascPig: false,

  //filters
  filtersPig: {
    date_start: { type: 'date', label: 'Start Date', value: start },
    date_end: { type: 'date', label: 'End Date', value: end },
    body_no: { type: 'text', label: 'Body Number', value: '' },
    producer: {
      type: 'select',
      name: 'code',
      label: 'Grower',
      value: '',
      options: []
    },
    allocation_destination_co_id: {
      type: 'select',
      name: 'company_id',
      label: 'Destination',
      selectLabel: 'name',
      value: '',
      options: [],
      customOptions: (option) => {
        return `${option.code} - ${option.name}`
      }
    },
    week: { type: 'week', label: 'Week Nr', value: getISOWeekNumber() },
    year: { type: 'year', value: getYear(), hidden: true }
  },
  //multiSorting
  sortingPig: [
    { label: 'Sex', field: 'specification:sex', asc: true, id: 0 },
    {
      label: 'Carcas Type',
      field: 'specification:carcase_type',
      asc: true,
      id: 1
    },
    { label: 'Hot Weight', field: 'specification:HSCW', asc: true, id: 2 },
    { label: 'Cold Weight', field: 'specification:CSCW', asc: true, id: 3 },
    { label: 'P2', field: 'specification:p2', asc: true, id: 4 }
  ],
  sortMapPig: {
    sex: 'specification:sex',
    carcase_type: 'specification:carcase_type',
    HSCW: 'specification:HSCW',
    CSCW: 'specification:CSCW',
    cat: 'specification:cat',
    date: 'date',
    body_no: 'body_no',
    lot: 'lot',
    yield: 'value:yield',
    p2: 'specification:p2'
  }
}
const getters = {
  pigInfoList: (state) => state.pigInfoList,
  pigInfoTotals: (state) => state.pigInfoTotals,

  runReport: (state) => state.runReport,

  loadingPigInfo: (state) => state.loadingPigInfo,
  loadingAllocations: (state) => state.loadingAllocations,
  totalPigInfo: (state) => state.totalPig,
  perPagePigInfo: (state) => state.perPagePig,
  pageNumberPig: (state) => state.pageNrPig,

  startDatePig: (state) => state.startDatePig,
  startDayPig: (state) => state.startDayPig,
  startWeekPig: (state) => state.startWeekPig,

  sortMapPig: (state) => state.sortMapPig,
  sortbyPig: (state) => state.sortbyPig,
  multiSortbyPig: (state) => state.multiSortbyPig,
  ascPig: (state) => state.ascPig,

  filtersPig: (state) => state.filtersPig,
  sortingPig: (state) => state.sortingPig,
  offsetPig: (state) => {
    return state.pageNrPig * state.perPagePig
  }
}
const actions = {
  async fetchPigInfo({ commit, dispatch, getters }, { vm }) {
    commit('SET_LOADING_PIG_INFO', 'list')
    const sortBy = getters.sortMapPig[getters.sortbyPig]
    const res = await dispatch(
      'fetch',
      {
        endpoint: 'piginfo/',
        parameters: {
          limit: getters.perPagePigInfo,
          offset: getters.offsetPig,
          sort_by: [
            getters.ascPig ? sortBy : `${sortBy}|-`,
            ...getters.multiSortbyPig
          ].join(',')
        },
        filters: getters.filtersPig
      },
      { root: true }
    )
    commit('SET_LOADING_PIG_INFO', '')
    if (!res.error) {
      const data = await Promise.all(
        res.data.map(async (element) => {
          const destructedPig = await dispatch('destructPigInfo', element)
          return destructedPig
        })
      )
      commit('SET_PIG_INFO_LIST', data)
      commit('SET_TOTAL_PIG', res.total)
      try {
        let minDateStr = getters.filtersPig.date_start.value
        if (minDateStr === '') {
          // Get Min date
          const minDate = new Date(
            Math.min(
              ...res.data.map((element) => {
                return new Date(element.date)
              })
            )
          )
          minDateStr = formatDateWithAddedDays(minDate, 0)
        }
        let startDate = await dispatch(
          'getWeeks',
          { dataDate: minDateStr },
          { root: true }
        )
        commit('SET_START_DATE_PIG', minDateStr)
        commit('SET_START_WEEK_PIG', 'W' + startDate[1])
        commit('SET_START_DAY_PIG', startDate[2])
      } catch (error) {
        console.error(error)
        commit('SET_START_DATE_PIG', '')
        commit('SET_START_DAY_PIG', '')
        commit('SET_START_WEEK_PIG', '')
      }
    } else {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: res.error,
          title: 'List of Pig Information',
          messagePrefix: 'Error while loading Pig Information! '
        },
        { root: true }
      )
    }
  },
  async createPigInfo({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'piginfo/'
    try {
      commit('SET_LOADING_PIG_INFO', 'create')
      await axios.post(URI, payload)
      commit('SET_LOADING_PIG_INFO', '')
      vm.$bvToast.toast(
        'New Pig Information created successfuly! ' + payload.name,
        {
          title: 'Create Pig Information',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_PIG_INFO', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Create Pig Information Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async destructPigInfo(attr, payload) {
    const { specification, value, plantlog, allocation, ...rest } = payload
    allocation.forEach((alloc) => {
      if (alloc.transport_id) return
      alloc.transport_id = ''
    })
    const result = {
      ...rest,
      ...specification,
      ...plantlog,
      allocation,
      ...value
    }
    return result
  },
  async updatePigInfo({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'piginfo/' + payload.id
    try {
      commit('SET_LOADING_PIG_INFO', `update-${payload.id}`)
      const res = await axios.patch(URI, payload)
      commit('SET_LOADING_PIG_INFO', '')
      vm.$bvToast.toast(
        'Pig body No: ' + payload.body_no + ' updated successfuly!',
        {
          title: 'Update Pig Information',
          variant: 'success',
          solid: true
        }
      )

      return res.data
    } catch (error) {
      commit('SET_LOADING_PIG_INFO', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Update Pig Information Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async deletePigInfo({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'piginfo/' + payload.id
    try {
      commit('SET_LOADING_PIG_INFO', 'delete')
      await axios.delete(URI)
      commit('SET_LOADING_PIG_INFO', '')
      vm.$bvToast.toast(
        'Pig Information deleted successfuly! ' + payload.name,
        {
          title: 'Delete Pig Information',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_PIG_INFO', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Pig Information Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async bulkDeletePigInfo({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'piginfo/'
    try {
      commit('SET_LOADING_PIG_INFO', 'bulk-delete')
      const res = await axios.delete(URI, { data: payload })

      vm.$bvToast.toast(`${res.data}`, {
        title: 'Delete Pigs - Success',
        variant: 'success',
        solid: true
      })

      return 'success'
    } catch (error) {
      await dispatch(
        'handleError',
        { vm, error, title: 'Delete Pigs Error' },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_PIG_INFO', '')
    }
  },
  async allocatePig({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'piginfo/allocation/'
    try {
      const pigIds = payload.map((pig) => pig.pig_id)
      commit('SET_LOADING_ALLOCATIONS', pigIds)
      let res = await axios.patch(URI, payload)
      vm.$bvToast.toast('Pig body parts allocated successfuly!', {
        title: 'Pig Allocation',
        variant: 'success',
        solid: true
      })
      return res.data
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Allocate Pig Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_ALLOCATIONS', [])
    }
  },
  async updateAllocation({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'piginfo/allocation/' + payload.id
    try {
      const pigId = payload.pig_id.toString()
      commit('SET_LOADING_ALLOCATIONS', [pigId])
      let res = await axios.patch(URI, payload)

      vm.$bvToast.toast('Allocation updated successfuly!', {
        title: 'Pig Allocation',
        variant: 'success',
        solid: true
      })
      return res.data
    } catch (error) {
      commit('SET_LOADING_ALLOCATIONS', [])
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Allocate Pig Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async deleteAllocation({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'piginfo/allocation/' + payload.id
    try {
      commit('SET_LOADING_ALLOCATIONS', [payload.pig_id.toString()])
      await axios.delete(URI)
      vm.$bvToast.toast(payload.part.part + ': ' + payload.destination.name, {
        title: 'Delete Allocation - Success',
        variant: 'success',
        solid: true
      })

      return 'success'
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Allocation Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_ALLOCATIONS', [])
    }
  },
  async bulkDeleteAllocation(
    { rootGetters, commit, dispatch },
    { payload, vm }
  ) {
    const URI = rootGetters.baseUrl + 'piginfo/allocation/'
    try {
      const pigIds = payload.map((alloc) => alloc.pig_id)
      commit('SET_LOADING_ALLOCATIONS', pigIds)
      const res = await axios.delete(URI, { data: payload })
      vm.$bvToast.toast(`${res.data}`, {
        title: 'Delete Allocation - Success',
        variant: 'success',
        solid: true
      })
      return 'success'
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Allocation Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_ALLOCATIONS', [])
    }
  },
  async clearFiltersPig({ commit }) {
    commit('SET_FILTER_PIG', { field: 'date_start', value: '' })
    commit('SET_FILTER_PIG', { field: 'date_end', value: '' })
    commit('SET_FILTER_PIG', { field: 'body_no', value: '' })
    commit('SET_FILTER_PIG', { field: 'producer', value: null })
    commit('SET_FILTER_PIG', {
      field: 'allocation_destination_co_id',
      value: null
    })
    commit('SET_FILTER_PIG', { field: 'week', value: null })
  },
  async createRunReport({ getters, commit, dispatch }) {
    await dispatch('resetRunReport')
    commit('RESET_PIG_INFO_TOTALS')
    getters.pigInfoList.forEach((element) => {
      if (!element.allocation.length) {
        let copy = JSON.parse(JSON.stringify(element))
        copy.product = 'WholePig'
        commit('SET_RUN_REPORT_ITEM', { cat: 'unalloc', data: copy })
        return
      }
      if (element.allocation.length >= 1) {
        element.allocation.forEach((alloc) => {
          commit('ADD_TO_PIG_INFO_TOTALS', alloc.part.part)

          let copy2 = JSON.parse(JSON.stringify(element))
          copy2.product = alloc.part.part
          copy2.customer = alloc.destination.name
          copy2.customerCompanyId = alloc.destination.id

          if (alloc.destination.name == 'Booyong Pork Processing') {
            commit('SET_RUN_REPORT_ITEM', { cat: 'boning', data: copy2 })
            dispatch('addItemToBreakBoningList', {
              pig: copy2,
              allocation: alloc
            })
          } else {
            const dow = getISOWeekday(element.date)
            const transport = alloc.destination.transport_schedule.find(
              (item) => item.weekday === dow
            )
            copy2.transport_ids = transport
              ? transport.schedule.delivery_co_ids
              : ''
            dispatch('addItemToBreakBoningList', {
              pig: copy2,
              allocation: alloc
            })
            if (!element.transport_docket) {
              commit('SET_RUN_REPORT_ITEM', { cat: 'incomplete', data: copy2 })
            } else {
              commit('SET_RUN_REPORT_ITEM', { cat: 'completed', data: copy2 })
            }
          }
        })
        return
      }
    })
  },
  addItemToBreakBoningList({ commit }, { pig, allocation }) {
    if (allocation.part.part === 'WholePig') return

    let pigCopy = JSON.parse(JSON.stringify(pig))
    pigCopy.tableId = `${pig.id}-${pig.product}`
    commit('SET_RUN_REPORT_ITEM', { cat: 'breakBoning', data: pigCopy })
  },
  async resetRunReport({ commit, getters }) {
    for (const key of Object.keys(getters.runReport)) {
      commit('RESET_RUN_REPORT_ITEM', key)
    }
    return
  }
}
const mutations = {
  ADD_PIG_TO_INFO_LIST: (state, { index, pig }) => {
    state.pigInfoList = [
      ...state.pigInfoList.slice(0, index),
      pig,
      ...state.pigInfoList.slice(index + 1)
    ]
  },
  SET_PIG_INFO_LIST: (state, payload) => (state.pigInfoList = payload),
  SET_RUN_REPORT_ITEM: (state, { cat, data, skipTotal = false }) => {
    state.runReport[cat].pigs.push(data)
    if (cat != 'unalloc' && skipTotal == false) {
      state.runReport[cat].totals[data.product] += 1
    }
  },
  ADD_TO_PIG_INFO_TOTALS: (state, payload) => {
    state.pigInfoTotals[payload] += 1
  },
  RESET_PIG_INFO_TOTALS: (state) => {
    state.pigInfoTotals = {
      WholePig: 0,
      Trunk: 0,
      Haunch: 0,
      Forequarter: 0,
      Saddle: 0,
      Leg: 0,
      HalfPig1: 0,
      HalfPig2: 0
    }
  },
  RESET_RUN_REPORT_ITEM: (state, payload) => {
    state.runReport[payload].pigs = []
    if (payload != 'unalloc') {
      state.runReport[payload].totals = {
        WholePig: 0,
        Trunk: 0,
        Haunch: 0,
        Forequarter: 0,
        Saddle: 0,
        Leg: 0,
        HalfPig1: 0,
        HalfPig2: 0
      }
    }
  },

  SET_TOTAL_PIG: (state, payload) => (state.totalPig = payload),

  SET_START_DATE_PIG: (state, payload) => (state.startDatePig = payload),
  SET_START_DAY_PIG: (state, payload) => (state.startDayPig = payload),
  SET_START_WEEK_PIG: (state, payload) => (state.startWeekPig = payload),
  SET_PAGE_NR_PIG: (state, payload) => (state.pageNrPig = payload),
  SET_LOADING_PIG_INFO: (state, payload) => (state.loadingPigInfo = payload),
  SET_LOADING_ALLOCATIONS: (state, payload) =>
    (state.loadingAllocations = payload),
  SET_PER_PAGE_PIG_INFO: (state, payload) => (state.perPagePig = payload),

  SET_SORT_BY_PIG: (state, payload) => (state.sortbyPig = payload),
  SET_MULTI_SORT_BY_PIG: (state, payload) => {
    state.multiSortbyPig = payload.map(({ field, asc }) => {
      let sort = field.replace('|-', '')
      return asc ? sort : `${sort}|-`
    })
  },
  SET_ASC_PIG: (state, payload) => (state.ascPig = payload),
  SET_SORTING_DIRECTION: (state, { id, asc }) =>
    (state.sortingPig[id].asc = asc),

  SET_FILTER_PIG: (state, { field, value }) => {
    const filter = state.filtersPig[field]

    if (filter.type !== 'select') {
      filter.value = value
      return
    }

    filter.value = value ?? ''
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
