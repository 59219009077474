import axios from 'axios'

import { PER_PAGE_OPTIONS } from '@/constants/pagination'
import { getISOWeekNumber, getWeekBounds, getYear } from '@/utils/DateHelpers'
import { BOOYONG_COMPANY_ID, STORAGE_COMPANY_IDS } from '@/constants/general'

const { start, end } = getWeekBounds()

async function getFilteredTransportPrice(data, id) {
  let price = 0
  if (!data.transport_cost || data.transport_cost.length == 0) {
    return price
  }
  data.transport_cost.forEach((cost) => {
    if (cost.freight_company_id == id) {
      price = cost.cost_ex_gst
      return
    }
  })
  return price
}

const state = {
  transportList: null,
  transportListSorted: null,
  destinationList: [],
  freightList: [],
  loadingTransport: '',

  //summary
  transportSummary: {
    pigsWeight: 0,
    cartonsWeight: 0,
    cartons: 0,
    pigs: 0,
    pigsPrice: 0,
    cartonsPrice: 0,
    splitPricePigs: 0,
    splitPriceCartons: 0
  },

  //pagination
  totalTransp: 0,
  perPageTransp: PER_PAGE_OPTIONS[0],
  pageNrTransp: 0,

  //date
  startDateTransp: '',
  startDayTransp: '',
  startWeekTransp: '',

  //sorting
  sortbyTransp: 'date|-,docket',

  //filters
  freightFilterInfo: null,
  filtersTransp: {
    date_start: { type: 'date', label: 'Start Date', value: start },
    date_end: { type: 'date', label: 'End Date', value: end },
    docket: { type: 'text', label: 'Docket Number', value: '' },
    invoice: { type: 'text', label: 'Invoice Number', value: '' },
    has_invoice: {
      type: 'checkbox',
      label: 'Invoice Status',
      value: [],
      options: [
        { text: 'Invoiced', value: true },
        { text: 'Non-Invoiced', value: false }
      ]
    },
    delivery_by: {
      type: 'select',
      name: 'name',
      label: 'Transport Company',
      value: '',
      noReduce: true,
      customOptions: (option) => {
        return `${option.code} - ${option.name}`
      },
      options: []
    },
    destination: {
      type: 'select',
      name: 'name',
      label: 'Customer',
      value: '',

      options: []
    },
    week: { type: 'week', label: 'Week Nr', value: getISOWeekNumber() },
    year: { type: 'year', value: getYear(), hidden: true }
  }
}
const getters = {
  transportList: (state) => state.transportList,
  transportListSorted: (state) => state.transportListSorted,
  destinationList: (state) => state.destinationList,
  freightList: (state) => state.freightList,
  transportSummary: (state) => {
    return state.transportSummary
  },

  loadingTransport: (state) => state.loadingTransport,
  totalTransport: (state) => state.totalTransp,
  perPageTransport: (state) => state.perPageTransp,
  pageNumberTransp: (state) => state.pageNrTransp,
  sortbyTransp: (state) => state.sortbyTransp,

  filtersTransp: (state) => state.filtersTransp,
  freightFilterInfo: (state) => state.freightFilterInfo,

  offsetTransp: (state) => {
    return state.perPageTransp * state.pageNrTransp
  },

  startDateTransp: (state) => state.startDateTransp,
  startDayTransp: (state) => state.startDayTransp,
  startWeekTransp: (state) => state.startWeekTransp
}
const actions = {
  async fetchTransport({ commit, dispatch, getters }, { vm }) {
    commit('SET_LOADING_TRANSPORT', 'list')
    let res = await dispatch(
      'fetch',
      {
        endpoint: 'transport/',
        parameters: {
          limit: getters.perPageTransport,
          offset: getters.offsetTransp,
          sort_by: getters.sortbyTransp
        },
        filters: getters.filtersTransp
      },
      { root: true }
    )
    commit('SET_LOADING_TRANSPORT', '')
    if (!res.error) {
      await commit('RESET_TRANSP_SUMMARY')
      await res.data.forEach(async (element) => {
        if (state.filtersTransp.delivery_by.value) {
          const freightId = getters.freightFilterInfo.company_id
          element.job_description.filteredPrice =
            await getFilteredTransportPrice(element.job_description, freightId)
        }
        commit('UPDATE_TRANSP_SUMMARY', element.job_description)
        element.loaded_items = element.loaded_items.map(
          ({ item_detail, ...rest }) => ({
            ...item_detail,
            ...rest
          })
        )
      })
      commit('SET_TRANSPORT_LIST', res.data)
      commit('SET_TRANSPORT_LIST_SORTED', res.data)
      commit('SET_TOTAL_TRANSP', res.total)

      try {
        let minDateStr = getters.filtersTransp.date_start.value
        if (minDateStr === '') {
          minDateStr = res.data[0].date
        }
        let startDate = await dispatch(
          'getWeeks',
          {
            dataDate: minDateStr
          },
          { root: true }
        )
        commit('SET_START_DATE_TRANSP', minDateStr)
        commit('SET_START_WEEK_TRANSP', 'W' + startDate[1])
        commit('SET_START_DAY_TRANSP', startDate[2])
      } catch (error) {
        commit('SET_START_DATE_TRANSP', '')
        commit('SET_START_DAY_TRANSP', '')
        commit('SET_START_WEEK_TRANSP', '')
      }
    } else {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: res.error,
          title: 'List of Transport',
          messagePrefix: 'Error while loading Transport! '
        },
        { root: true }
      )
    }
  },
  async fetchDestinations({ rootGetters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + 'transport/destinationlist'
    try {
      commit('SET_LOADING_TRANSPORT', 'destination')
      let res = await axios.get(URI)
      const destinationList = res.data.data.filter(
        (item) =>
          !(
            item.company_id === BOOYONG_COMPANY_ID && item.type === 'Customer'
          ) &&
          !(
            STORAGE_COMPANY_IDS.includes(item.company_id) &&
            item.type === 'Customer'
          )
      )
      commit('SET_DESTINATION_LIST', destinationList)
      commit('UPDATE_DESTINATION_FILTER', destinationList)
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'List of Destination',
          messagePrefix: 'Error while loading destination list! '
        },
        { root: true }
      )
    } finally {
      commit('SET_LOADING_TRANSPORT', '')
    }
  },
  async fetchFreight({ rootGetters, commit, dispatch }, { vm }) {
    const URI = rootGetters.baseUrl + 'supplier/freightnames?sort_by=name'
    try {
      commit('SET_LOADING_TRANSPORT', 'freight')
      let res = await axios.get(URI)
      commit('SET_FREIGHT_LIST', res.data.data)
      commit('UPDATE_FREIGHT_FILTER', res.data.data)
    } catch (error) {
      const messagePrefix = 'Error while loading freight list! '
      if (rootGetters.usingVuetify) {
        dispatch('showError', { error, messagePrefix })
      }
      await dispatch(
        'handleError',
        { vm, error, title: 'List of Freight', messagePrefix },
        { root: true }
      )
    } finally {
      commit('SET_LOADING_TRANSPORT', '')
    }
  },
  async createTransport({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'transport/'
    try {
      commit('SET_LOADING_TRANSPORT', 'create')
      await axios.post(URI, payload)
      commit('SET_LOADING_TRANSPORT', '')
      vm.$bvToast.toast(
        'New Transport created successfuly! ' + payload.company.name,
        {
          title: 'Create Transport',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_TRANSPORT', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Create Transport Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async updateTransport({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'transport/' + payload.id
    try {
      commit('SET_LOADING_TRANSPORT', 'update')
      const res = await axios.patch(URI, payload)
      const name = payload.company?.name ?? ''
      vm.$bvToast.toast(`Transport: ${name} information updated successfuly!`, {
        title: 'Update Transport',
        variant: 'success',
        solid: true
      })

      return res
    } catch (error) {
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Update Transport Error'
        },
        { root: true }
      )
      return 'error'
    } finally {
      commit('SET_LOADING_TRANSPORT', '')
    }
  },
  async createSchedule({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'transport/schedule/'
    try {
      commit('SET_LOADING_TRANSPORT', 'schedule')
      let res = await axios.post(URI, payload)
      commit('SET_LOADING_TRANSPORT', '')
      vm.$bvToast.toast('Schedule information created successfuly!', {
        title: 'Create Schedule',
        variant: 'success',
        solid: true
      })
      return res
    } catch (error) {
      commit('SET_LOADING_TRANSPORT', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Create Schedule Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async updateSchedule({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'transport/schedule/' + payload.id
    try {
      commit('SET_LOADING_TRANSPORT', 'schedule')
      let res = await axios.patch(URI, payload)
      commit('SET_LOADING_TRANSPORT', '')
      vm.$bvToast.toast('Schedule information updated successfuly!', {
        title: 'Update Schedule',
        variant: 'success',
        solid: true
      })
      return res
    } catch (error) {
      commit('SET_LOADING_TRANSPORT', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Update Schedule Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async deleteTransport({ rootGetters, commit, dispatch }, { payload, vm }) {
    const URI = rootGetters.baseUrl + 'transport/' + payload.id
    try {
      commit('SET_LOADING_TRANSPORT', 'delete')
      await axios.delete(URI)
      commit('SET_LOADING_TRANSPORT', '')
      vm.$bvToast.toast(
        'Transport deleted successfuly! ' + payload.company.name,
        {
          title: 'Delete Transport',
          variant: 'success',
          solid: true
        }
      )

      return 'success'
    } catch (error) {
      commit('SET_LOADING_TRANSPORT', '')
      await dispatch(
        'handleError',
        {
          vm: vm,
          error: error,
          title: 'Delete Transport Error'
        },
        { root: true }
      )
      return 'error'
    }
  },
  async clearFiltersTransp({ commit }) {
    commit('SET_FILTER_TRANSP', { field: 'date_start', value: '' })
    commit('SET_FILTER_TRANSP', { field: 'date_end', value: '' })
    commit('SET_FILTER_TRANSP', { field: 'docket', value: '' })
    commit('SET_FILTER_TRANSP', { field: 'invoice', value: '' })
    commit('SET_FILTER_TRANSP', { field: 'has_invoice', value: [] })
    commit('SET_FILTER_TRANSP', { field: 'destination', value: '' })
    commit('SET_FILTER_TRANSP', { field: 'delivery_by', value: '' })
    commit('SET_FILTER_TRANSP', { field: 'week', value: null })
  }
}
const mutations = {
  SET_TRANSPORT_LIST: (state, payload) => (state.transportList = payload),
  SET_TRANSPORT_LIST_SORTED: (state, payload) => {
    let dataCopy = JSON.parse(JSON.stringify(payload))
    dataCopy.sort(function (a, b) {
      if (a.destination.name < b.destination.name) {
        return -1
      }
      if (b.destination.name < a.destination.name) {
        return 1
      }
      return 0
    })
    state.transportListSorted = dataCopy
  },
  SET_DESTINATION_LIST: (state, payload) => (state.destinationList = payload),
  SET_FREIGHT_LIST: (state, payload) => (state.freightList = payload),

  UPDATE_TRANSP_SUMMARY: (state, payload) => {
    const parseNumber = (num) => (isNaN(num) ? 0 : num)

    const updateSummary = (type) => {
      state.transportSummary[`${type}Weight`] += parseNumber(
        payload.total_weight
      )
      state.transportSummary[type] += parseNumber(payload[`total_${type}`])
      state.transportSummary[`${type}Price`] += parseNumber(
        payload.total_ex_GST
      )

      if (state.filtersTransp.delivery_by.value && payload.filteredPrice) {
        const capType = type.charAt(0).toUpperCase() + type.slice(1)
        state.transportSummary[`splitPrice${capType}`] += payload.filteredPrice
      }
    }

    if ('total_pigs' in payload) {
      updateSummary('pigs')
    } else if ('total_cartons' in payload) {
      updateSummary('cartons')
    }
  },
  RESET_TRANSP_SUMMARY: (state) => {
    for (const key of Object.keys(state.transportSummary)) {
      state.transportSummary[key] = 0
    }
  },
  SET_START_DATE_TRANSP: (state, payload) => (state.startDateTransp = payload),
  SET_START_DAY_TRANSP: (state, payload) => (state.startDayTransp = payload),
  SET_START_WEEK_TRANSP: (state, payload) => (state.startWeekTransp = payload),
  SET_TOTAL_TRANSP: (state, payload) => (state.totalTransp = payload),
  SET_PAGE_NR_TRANSP: (state, payload) => (state.pageNrTransp = payload),
  SET_LOADING_TRANSPORT: (state, payload) => (state.loadingTransport = payload),
  SET_PER_PAGE_TRANSPORT: (state, payload) => (state.perPageTransp = payload),
  SET_SORT_BY_TRANSP: (state, payload) => (state.sortbyTransp = payload),
  UPDATE_FREIGHT_FILTER: (state, payload) => {
    // sort by code
    payload.sort(function (a, b) {
      if (a.code < b.code) {
        return -1
      }
      if (b.code < a.code) {
        return 1
      }
      return 0
    })
    state.filtersTransp.delivery_by.options = payload
  },
  UPDATE_DESTINATION_FILTER: (state, payload) => {
    payload.sort(function (a, b) {
      if (a.code < b.code) {
        return -1
      }
      if (b.code < a.code) {
        return 1
      }
      return 0
    })
    state.filtersTransp.destination.options = payload
  },
  SET_FILTER_TRANSP: (state, payload) => {
    if (
      state.filtersTransp[payload.field].type === 'checkbox' &&
      payload.value.length == 0
    ) {
      while (state.filtersTransp.has_invoice.value.length > 0) {
        state.filtersTransp.has_invoice.value.pop()
      }
      return
    }
    if (state.filtersTransp[payload.field].type === 'select') {
      if (payload.value) {
        state.filtersTransp[payload.field].value = payload.value
      } else {
        state.filtersTransp[payload.field].value = ''
      }
      return
    }
    state.filtersTransp[payload.field].value = payload.value
  },
  SET_FREIGHT_FILTER_INFO: (state, payload) => {
    state.freightFilterInfo = payload
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
