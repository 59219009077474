<script setup>
import BaseAlert from '@/components/base/BaseAlert.vue'
import ProductStockFilter from '@/components/products-stock-page/ProductStockFilter.vue'
import ProductScanner from '@/components/products-stock-page/ProductScanner.vue'
import ProductStockList from '@/components/products-stock-page/ProductStockList.vue'
import ProductStockSummary from '@/components/products-stock-page/ProductStockSummary.vue'
import PaginationBase from '@/components/PaginationBase.vue'

import { useStore } from '@/utils/VuexHelpers'
import { PRODUCTS } from '@/constants/pagination'
import { computed, onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router/composables'

const route = useRoute()
const router = useRouter()
const store = useStore()

const activeTab = ref({
  tab0: true,
  tab1: false,
  tab2: false,
  tab3: false,
  tab4: false
})

const totalStock = computed(() => store.getters.totalStock)
const perPageStock = computed(() => store.getters.perPageStock)
const perPageOptions = computed(() => store.getters.perPageOptions)
const pageNumberStock = computed(() => store.getters.pageNumberStock)

const perPage = computed({
  get: () => perPageStock.value,
  set: (value) => {
    store.commit('SET_PER_PAGE_STOCK', value)
    store.dispatch('fetchStock')
  }
})

const updatePageNr = (nr) => {
  store.commit('SET_PAGE_NR_STOCK', nr - 1)
  store.dispatch('fetchStock')
}

const toggleTabs = (id) => {
  if (route.query.tab == id) return
  setTimeout(() => {
    routerReplace(id)
  }, 200)
}
const routerReplace = (id) => {
  router.replace({ name: 'productStock', query: { tab: id } })
}

watch(
  () => route,
  (value) => {
    const { tab: id } = value.query
    if (activeTab.value[`tab${id}`] !== true) {
      activeTab.value[`tab${id}`] = true
    }
  }
)

onMounted(() => {
  const { tab } = route.query

  if (tab) {
    activeTab.value[`tab${tab}`] = true
  }
})

// ON CREATED
store.commit('SET_USING_VUETIFY', true)
store.commit('SET_ACTIVE', PRODUCTS)
</script>

<template>
  <v-app>
    <BaseAlert />
    <v-container fluid>
      <div class="wrapper">
        <ProductStockFilter />
        <div class="content-page mt-0 ps-4">
          <v-row no-gutters class="my-2 align-center">
            <div class="page-title-box d-flex">
              <h4 class="page-title">Product Stock</h4>
            </div>
          </v-row>
          <v-row class="mt-1 mb-4 align-center" no-gutters>
            <div class="d-flex align-center">
              Total Product Stock:
              <span class="font-weight-bold ml-2">{{ totalStock }}</span>
            </div>
            <div class="ms-auto mr-2 d-flex align-center">
              Product stock per page:
            </div>
            <b-select
              v-model="perPage"
              :options="perPageOptions"
              class="d-inline-block w-auto mr-4"
            ></b-select>
            <PaginationBase
              :total="totalStock"
              :perPage="perPageStock"
              :current="pageNumberStock + 1"
              @updatePage="updatePageNr"
            />
          </v-row>
          <b-card no-body>
            <b-tabs id="stock-tab-list" card @activate-tab="toggleTabs">
              <b-tab
                title="All Stock"
                :active.sync="activeTab.tab0"
                title-item-class="text-dark"
                class="px-0 pt-2"
              >
                <template #title>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      icon="fas fa-box-open"
                      size="sm"
                      class="mr-2 text-warning"
                    />
                    All Stock
                  </div>
                </template>
                <ProductStockList />
              </b-tab>
              <b-tab :active.sync="activeTab.tab1" lazy class="summary-tab">
                <template #title>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      :icon="['far', 'file-alt']"
                      size="sm"
                      class="mr-2 text-success"
                    />Summary
                  </div>
                </template>
                <ProductStockSummary />
              </b-tab>
              <b-tab :active.sync="activeTab.tab2" lazy>
                <template #title>
                  <div class="d-flex align-items-center">
                    <font-awesome-icon
                      :icon="['fas', 'barcode']"
                      size="sm"
                      class="mr-2 text-dark"
                    />Scanner
                  </div>
                </template>
                <ProductScanner :currentIndex="activeTab.tab2"
              /></b-tab>
            </b-tabs>
          </b-card>
        </div>
      </div>
    </v-container>
  </v-app>
</template>

<style>
.summary-tab {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
