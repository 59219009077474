<script setup>
import UpdateProductStockStatusModal from '@/components/products-stock-page/UpdateProductStockStatusModal.vue'
import ProductStockUpdateForm from './ProductStockUpdateForm.vue'

import {
  formatCurrency,
  formatDate,
  formatKilograms,
  formatDecimals
} from '@/helpers/stringHelpers'
import { useStore } from '@/utils/VuexHelpers'
import { computed, ref } from 'vue'

const EXTERNAL_STR = 'external'

const store = useStore()

const stockStatusModal = ref(null)
const selectedStock = ref([])

const headers = [
  { value: 'plantlog', text: 'Prod Date', sortable: true },
  { value: 'weight', text: 'Weight (kg)', sortable: false, align: 'end' },
  { value: 'product', text: 'Product/Code', sortable: false },
  { value: 'barcode', text: 'Barcode', sortable: false },
  { value: 'location', text: 'Location/Docket', sortable: false },
  { value: 'status', text: 'Status', sortable: false, align: 'center' },
  { value: 'actions', text: 'Actions', sortable: false, align: 'center' }
]

const stockList = computed(() => store.getters.stockList)
const stockDestinationList = computed(() => store.getters.stockDestinationList)
const loadingStock = computed(() => store.getters.loadingStock)
const totalStockWeight = computed(() =>
  formatKilograms(store.getters.totalStockWeight, 2)
)
const perPageStock = computed(() => store.getters.perPageStock)
const sortBy = computed(() => store.getters.sortbyStock)
const sortDesc = computed(() => !store.getters.ascStock)
const filtersStock = computed(() => store.getters.filtersStock)

const totalStockPrice = computed(() => {
  const total = stockList.value.reduce((total, item) => {
    return (total += item.product_desc.price_kg * item.weight)
  }, 0)
  return formatCurrency(total)
})

const relevantFilters = [
  'docket',
  'prod_date_end',
  'prod_date_start',
  'destination_co_id',
  'product_name',
  'status'
]
const showTotals = computed(() => {
  return relevantFilters.some((filter) => filtersStock.value[filter]?.value)
})

const resetSelected = () => {
  selectedStock.value = []
}

const sortChange = ({ sortBy, sortDesc }) => {
  store.commit('SET_ASC_STOCK', !sortDesc[0])
  store.commit('SET_SORT_BY_STOCK', sortBy[0])
  store.dispatch('fetchStock')
}

const isExternalStock = (item) => {
  const description = item.product_desc

  if (description.type.toLowerCase().includes(EXTERNAL_STR)) return true
  if (description.barcode && description.barcode.trim() !== '') return true
  if (description.comment.toLowerCase().includes(EXTERNAL_STR)) return true

  return false
}

const statusStyles = {
  inplant: { variant: 'primary', icon: ['fas', 'fa-bone'] },
  instorage: { variant: 'warning', icon: ['fas', 'fa-box-open'] },
  delivered: { variant: 'success', icon: ['fas', 'fa-check'] },
  expired: { variant: 'error', icon: ['fas', 'fa-times'] }
}

const getStatusVariant = (status) => {
  return statusStyles[status.toLowerCase()]?.variant || 'primary'
}

const getStatusIcon = (status) => {
  return statusStyles[status.toLowerCase()]?.icon || ['fas', 'minus']
}

const getDestination = (item) => item?.tracklog?.destination || '-'
const getDocket = (item) => item?.tracklog?.docket || '-'

// ON CREATED
if (
  stockDestinationList.value.length === 0 &&
  loadingStock.value !== 'destination'
) {
  store.dispatch('fetchProductDestinations')
}
</script>
<template>
  <v-container class="mx-0 mw-100">
    <!-- MODALS -->
    <UpdateProductStockStatusModal ref="stockStatusModal" />

    <v-data-table
      id="product-stock-table"
      v-model="selectedStock"
      :items="stockList"
      :headers="headers"
      :sort-by="sortBy"
      :sort-desc="sortDesc"
      @update:options="sortChange"
      @current-items="resetSelected"
      must-sort
      fixed-header
      height="800"
      class="w-100 elevation-2"
      :loading="loadingStock === 'list'"
      loading-text="Loading... Please wait"
      :show-select="true"
      :server-items-length="perPageStock"
      hide-default-footer
    >
      <template v-slot:top>
        <v-row
          v-if="showTotals"
          class="bg-light-blue p-2 justify-start align-center"
        >
          <v-col cols="auto" class="d-flex align-center"
            ><h6 class="text-muted mr-2 mb-0">Total Weight:</h6>
            <p class="mb-0 mr-2">{{ totalStockWeight }}</p></v-col
          >
          <v-col cols="auto" class="d-flex align-center"
            ><h6 class="text-muted mr-2 mb-0">Total Price:</h6>
            <p class="mb-0">{{ totalStockPrice }}</p></v-col
          >
          <v-col cols="12" class="pa-0">
            <v-divider class="ma-0"></v-divider>
          </v-col>
        </v-row>

        <v-row
          :class="showTotals ? 'mt-0' : ''"
          class="bg-light-blue p-2 justify-start align-center pt-0"
        >
          <v-col cols="auto"
            ><small class="text-uppercase"
              >Selected: {{ selectedStock.length }}</small
            ></v-col
          >
          <v-col cols="auto">
            <ProductStockUpdateForm :stock-items="selectedStock" />
          </v-col>
        </v-row>
      </template>
      <template v-slot:item.plantlog="{ value }">
        <div class="text-nowrap">
          {{ formatDate(value?.proddate) }}
        </div>
      </template>
      <template v-slot:item.weight="{ value }">
        <span class="text-light-blue"
          ><b>{{ formatDecimals(value, 2) }}</b></span
        >
      </template>
      <template v-slot:item.product="{ item }">
        <v-row no-gutters class="flex-column">
          <div>{{ item.product }}</div>
          <span class="d-flex align-items-center">
            <small class="font-italic"
              ><b class="text-muted">Code:</b
              ><span class="text-overline"> {{ item.code }}</span></small
            >
            <v-chip
              v-if="isExternalStock(item)"
              label
              x-small
              class="external-chip mx-2 px-1 font-weight-bold"
              >External Stock</v-chip
            >
          </span>
        </v-row>
      </template>
      <template v-slot:item.location="{ item }">
        <v-row no-gutters class="flex-column my-2">
          <div class="text-body-1">{{ getDestination(item) }}</div>
          <span class="d-flex align-items-center">
            <small class="font-italic"
              ><b class="text-muted">Docket No:</b
              ><span class="text-overline"> {{ getDocket(item) }}</span></small
            ></span
          >
        </v-row>
      </template>
      <template v-slot:item.status="{ value }">
        <v-chip :color="getStatusVariant(value)" label x-small
          ><font-awesome-icon :icon="getStatusIcon(value)" class="mr-1" />{{
            value.toUpperCase()
          }}</v-chip
        >
      </template>
      <template v-slot:item.actions="{ index, item }">
        <v-btn
          small
          text
          color="info"
          class="text-nowrap"
          @click="
            () =>
              stockStatusModal.updateStockStatus({
                ...item,
                index
              })
          "
        >
          <font-awesome-icon :icon="['fas', 'edit']" class="mr-1" />Edit</v-btn
        >
      </template>
    </v-data-table>
  </v-container>
</template>

<style scoped>
.text-overline {
  line-height: 1rem !important;
}
.external-chip {
  color: #212529 !important;
  background-color: #ffc107 !important;
}
</style>
