<script setup>
import { useStore } from '@/utils/VuexHelpers'
import { computed, ref, defineProps, defineEmits, watch } from 'vue'

const emit = defineEmits(['update:destination'])
const props = defineProps({
  destination: Number,
  stockStatus: {
    type: String,
    default: ''
  },
  label: {
    type: String,
    default: 'Destination'
  },
  required: {
    type: Boolean,
    default: false
  }
})

const store = useStore()
const stockDestinationList = computed(() => store.getters.stockDestinationList)
const locationBoning = computed(() => store.getters.locationBoning)
const locationStorage = computed(() => store.getters.locationStorage)
const locationCustomer = computed(() => store.getters.locationCustomer)

const loadingStock = computed(() => store.getters.loadingStock)
const isLoading = computed(() => loadingStock.value === 'destination')

const value = ref(null)

const destinationList = computed(() => {
  let copy = []

  switch (props.stockStatus.toLowerCase()) {
    case 'inplant':
      copy = JSON.parse(JSON.stringify(locationBoning.value))
      break
    case 'instorage':
      copy = JSON.parse(JSON.stringify(locationStorage.value))
      break
    case 'delivered':
      copy = JSON.parse(JSON.stringify(locationCustomer.value))
      break
    default:
      copy = JSON.parse(JSON.stringify(stockDestinationList.value))
  }

  return copy.sort((a, b) => a.name.localeCompare(b.name))
})

const customFilter = (item, queryText) => {
  const textOne = item.name.toLowerCase()
  const textTwo = item.code ? item.code.toLowerCase() : ''
  const searchText = queryText.toLowerCase().trim()

  return textOne.indexOf(searchText) > -1 || textTwo.indexOf(searchText) > -1
}

const updateValue = (value) => {
  emit('update:destination', value)
}

watch(
  () => props.destination,
  (newValue) => {
    if (value.value === newValue) return

    value.value = newValue
  },
  { immediate: true }
)

// ON CREATED
if (destinationList.value.length === 0 && !isLoading.value) {
  store.dispatch('fetchProductDestinations')
}
</script>

<template>
  <v-autocomplete
    v-model="value"
    :items="destinationList"
    :filter="customFilter"
    item-text="name"
    item-value="company_id"
    dense
    outlined
    :label="label"
    :loading="isLoading"
    :rules="required ? [(v) => !!v || 'Location is required'] : []"
    hide-details="auto"
    class="my-3"
    @change="updateValue"
  >
    <template v-slot:selection="{ item, selected, attrs }">
      <div v-bind="attrs" :input-value="selected">
        {{ `${item.code} - ${item.name}` }}
      </div>
    </template>
    <template v-slot:item="{ item }">
      <template v-if="typeof item !== 'object'">
        <v-list-item-content v-text="item"></v-list-item-content>
      </template>
      <template v-else>
        <v-list-item-content class="text-start">
          <v-list-item-title>
            {{ `${item.code} - ${item.name}` }}
          </v-list-item-title>
        </v-list-item-content>
      </template>
    </template>
  </v-autocomplete>
</template>
